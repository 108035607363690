import jQuery from 'jquery'

export function setUpPopovers() {
  const popoverTemplate = '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-inner"><div class="popover-body"><p></p></div></div></div>'
  jQuery('input[rel=popover], textarea[rel=popover], input[data-toggle=popover], textarea[data-toggle=popover]').popover({
    trigger: 'focus',
    html: true,
    template: popoverTemplate,
    placement: (popover, base) => jQuery(base).attr('data-placement') || 'right'
  })

  jQuery('a[rel=popover], a[data-toggle=popover], button[data-toggle=popover]').each(function() {
    jQuery(this).popover({
      trigger: jQuery(this).attr('data-trigger') || 'click',
      html: true,
      template: popoverTemplate,
      placement: jQuery(this).attr('data-placement') || 'right'
    })
  })
}

import I18n from 'i18n-js'

class I18nTranslations {
  constructor({translationContext}) {
    this.translationContext = translationContext

    if (this.translationsLoaded === undefined) {
      this.translationsLoaded = false
      this.translationsLoading = false
    }
    document.addEventListener('turbolinks:load', this.loadTranslations)

    // for pages not using turbolinks, we get document:ready event
    document.addEventListener('DOMContentLoaded', this.loadTranslations)
  }

  isRtl = () => {
    const htmlElement = document.getElementsByTagName('html')[0]
    if (htmlElement && htmlElement.attributes['dir']) {
      return htmlElement.attributes['dir'].value === 'rtl'
    }

    return false
  }

  loadTranslations = () => {
    // Do not attempt to reload translations if they were already initialized
    if (this.translationsLoaded) {
      // Trigger the event to forward turbolinks:load for other scripts
      document.dispatchEvent(new Event('translations:loaded'))
      return
    }

    if (this.translationsLoading) {
      // only allow one attempt at loading translations to be in progress at once.
      return
    }

    this.translationsLoading = true

    const currentLocale = htmlLang()
    if (!currentLocale) {
      throw 'Couldn\'t find current locale'
    }

    import(/* webpackMode: "lazy" */ `../locales/${this.translationContext}/${currentLocale}.js`)
      .then(({default: translations}) => {
        I18n.locale = currentLocale
        I18n.fallbacks = 'default_locale'
        I18n.translations[currentLocale] = translations
      }).then(() => {
        this.translationsLoaded = true
        this.translationsLoading = false
        document.dispatchEvent(new Event('translations:loaded'))
      })
  }

  locale() {
    this.translationsLoadedGuard()
    return I18n.locale
  }

  translate(scope, options) {
    this.translationsLoadedGuard()
    return I18n.translate(scope, options)
  }

  t(scope, options) {
    return this.translate(scope, options)
  }

  localize(scope, value, options) {
    this.translationsLoadedGuard()
    return I18n.localize(scope, value, options)
  }

  l(scope, value, options) {
    return this.localize(scope, value, options)
  }

  pluralize(count, scope, options) {
    this.translationsLoadedGuard()
    return I18n.pluralize(count, scope, options)
  }

  p(count, scope, options) {
    this.pluralize(count, scope, options)
  }

  toNumber(number, options) {
    this.translationsLoadedGuard()
    return I18n.toNumber(number, options)
  }

  strftime(time, string) {
    this.translationsLoadedGuard()
    return I18n.strftime(time, string)
  }

  translationsLoadedGuard() {
    if (!this.translationsLoaded) {
      throw 'Translations not loaded yet. Need to wait until translations:loaded before calling this function'
    }
  }
}

export const htmlLang = () => document.getElementsByTagName('html')[0].attributes['lang'].value

export default I18nTranslations

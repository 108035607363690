import jQuery from 'jquery'

export default function addComponentLoader(componentMapping, scriptMapping, storeGetterImport) {
  baseAddComponentLoader(componentMapping, scriptMapping, storeGetterImport, false, null)
}

export function addSharedStoreComponentLoader(componentList, scriptMapping, storeGetterImport, storeDataComponentName) {
  baseAddComponentLoader(componentList, scriptMapping, storeGetterImport, true, storeDataComponentName)
}

async function sharedStoreHandler(componentMapping, storeGetterImport, storeDataComponentName) {
  // Find all component loaders on the page
  const vueComponentDivs = document.getElementsByClassName('vue-component-loader')

  // Filter to just the ones in our componentMapping
  const relevantVueComponentDivs = Array.from(vueComponentDivs).filter(
    (div) => div.dataset.componentName in componentMapping
  )

  if (relevantVueComponentDivs.length === 0) {
    // None of the components we're looking for are on the page, so just exit.
    return
  }

  // Initialize the store! If storeDataComponentName is specified, that component's data
  // will be used to initialize the store.
  let vuexStore
  const { default: storeOrStoreGetter } = await storeGetterImport()
  if (typeof storeOrStoreGetter === 'function') {
    vuexStore = storeOrStoreGetter(storeDataComponentName)
  } else {
    vuexStore = storeOrStoreGetter
  }

  // Initialize each of the components in our mapping that are on the page,
  // hooking them all into the same vuex store.
  for (let div of relevantVueComponentDivs) {
    const componentData = div.dataset
    const componentName = componentData.componentName
    const props = JSON.parse(componentData.props)
    createVueComponent(div, componentMapping[componentName], props, vuexStore)
  }
}

async function unorderedHandler(componentMapping, storeGetterImport) {
  const vueComponentDivs = document.getElementsByClassName('vue-component-loader')

  for (let div of vueComponentDivs) {
    const componentData = jQuery(div).data()
    const componentName = componentData.componentName

    if (componentName in componentMapping) {
      const props = componentData.props ? componentData.props : componentData

      // Here, we expect that each component, if it has a store,
      // has a store that is independent from the other components being loaded.
      let vuexStore
      if (storeGetterImport) {
        const { default: storeOrStoreGetter } = await storeGetterImport()
        if (typeof storeOrStoreGetter === 'function') {
          vuexStore = storeOrStoreGetter(componentName)
        } else {
          vuexStore = storeOrStoreGetter
        }
      }
      createVueComponent(div, componentMapping[componentName], props, vuexStore)
    }
  }
}

function createVueComponent(div, definition, props, store) {
  const el = jQuery(div).children('.vue-component').first()[0]

  if (!el) {
    // This .vue-component-loader div has no .vue-component child.
    // This probably means it has already been processed, and there's nothing we need to do.
    return
  }

  // Transform existing list of classes ['class-a', 'class-b'] from the element into the format:
  // {
  //    class-a: true,
  //    class-b: true
  // }
  const classes = Object.fromEntries(Array.from(el.classList).map((val) => [val, true]))

  window.createRootVueComponent(el, definition, {...props, class: classes}, store)
}

function scriptHandler(scriptMapping) {
  const scriptDivs = document.getElementsByClassName('script-loader')

  for (let div of scriptDivs) {
    const scriptData = jQuery(div).data()
    const scriptName = scriptData.scriptName
    if (scriptName in scriptMapping) {
      scriptMapping[scriptName]()
    }
  }
}

function baseAddComponentLoader(componentCollection, scriptMapping, storeGetterImport, sharedStore = false, storeDataComponentName) {
  function handler() {
    if (sharedStore) {
      sharedStoreHandler(componentCollection, storeGetterImport, storeDataComponentName)
    }
    else {
      unorderedHandler(componentCollection, storeGetterImport)
    }
    scriptHandler(scriptMapping)
  }

  document.addEventListener('translations:loaded', () => {
    handler()
  })

  // The above event will not be fired again for our "remote" forms,
  // but we still need the vue component to be re-placed on the page.
  // Therefore, we fire this event for remote forms on update
  // if reloading vue components is needed.
  document.addEventListener('agra:need-vue-components', () => {
    handler()
  })
}

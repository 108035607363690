import { addSharedStoreComponentLoader } from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'

const componentsMapping = {
  eventImageUploadAndCrop: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "event-upload-and-crop" */ './upload-and-crop')),
  eventPromptsBanner: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "event-prompts-banner" */ '@/campaign-prompts/event-root'))
}

addSharedStoreComponentLoader(
  componentsMapping,
  {},
  () => import(/* webpackMode: "lazy" */ /* webpackChunkName: "event-manage-store-getter" */ './store'),
  null
)

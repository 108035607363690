import jQuery from 'jquery'
import { setUpTooltips } from 'tooltips'
import { setUpPopovers } from 'popovers'
import { convertAnchorsToEmbedsForSelector } from './embedly'

function potentialAnchorsToReplaceWithEmbedlyEmbeds() {
  const anchors = jQuery('.embedly a.embeddable')

  // Only replace "naked" anchors (i.e.: links that have no text other than the URL itself)
  return anchors.filter((_, element) => element.href === element.innerText)
}

jQuery(() => {
  // Activate bootstrap tooltips
  setUpTooltips()

  // Activate bootstrap popovers
  setUpPopovers()

  // Initialize embedly unless it's explicitly disabled
  const embedlyDisabled = window.Agra.Configuration.embedly.disabled
  if (!embedlyDisabled) {
    convertAnchorsToEmbedsForSelector(potentialAnchorsToReplaceWithEmbedlyEmbeds())

    document.addEventListener('agra:embedly-div-added', () => {
      convertAnchorsToEmbedsForSelector(potentialAnchorsToReplaceWithEmbedlyEmbeds())
    })
  }
})

import jQuery from 'jquery'
import { EU_COUNTRIES } from './eu-countries'

function showOrHideEuContent() {
  if (EU_COUNTRIES.includes(jQuery('.eu-only-content-switcher').val())) {
    jQuery('.eu-data-processing-consent-wrapper').show()
  } else {
    jQuery('.eu-data-processing-consent-wrapper').hide()
  }
}

jQuery(document).on('change', '.eu-only-content-switcher', showOrHideEuContent)

jQuery(document).ready(() => {
  if (jQuery('.eu-only-content-switcher').length > 0) {
    showOrHideEuContent()
  }
})

jQuery(document).on('form:loaded', () => {
  if (jQuery('.eu-only-content-switcher').length > 0) {
    showOrHideEuContent()
  }
})

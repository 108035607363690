import addComponentLoader from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'

const componentMapping =  {
  ajaxErrorToast: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "ajax-error-toast" */ '@/components/ajax-error-toast')),
  campaignAssociationField: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "campaign-association-field" */ '@/campaign-form-shared/campaign-association-field')),
  campaignCreatorBlock: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "campaign-creator-block" */ './campaign-creator-block')),
  contactGroupOrganiser: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "contact-group-organiser" */ '@/local-chapters/contact-organiser.vue')),
  copyUrlField: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "copy-url-field" */ '@/components/sharing/copy-url')),
  effortLocationsMap: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "effort-locations-map" */ './efforts/locations-map')),
  eventShifts: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "event-shifts" */ '@/events/shifts/event-shifts')),
  groupMembersList: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "group-members-list" */ '@/local-chapters/members-list')),
  groupsSearchBanner: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "groups-search-banner" */ '@/local-chapters/search-banner')),
  groupTitleBanner: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "group-title-banner" */ '@/local-chapters/title-banner.vue')),
  imageUploadAndCrop: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "image-upload-and-crop" */ '@/image/upload-and-crop')),
  inlineModerationField: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "inline-moderation-field" */ '@/components/inline-moderation-field')),
  locationAutocomplete: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "location-autocomplete" */ '@/components/inputs/locations/location-autocomplete')),
  locationSearch: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ '@/components/inputs/locations/location-search')),
  nationalLocalLocationSelector: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ '@/components/inputs/national-local-location-selector')),
  partnershipDateFilterableExportBlock: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "partnership-date-filterable-export-block" */ '@/partnerships/date-filterable-export-block')),
  petitionCarousel: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-carousel" */ '@/petition/carousel')),
  petitionCategoriesSelector: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-categories-selector" */ '@/components/petition-categories-selector')),
  petitionFlag: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-flag" */ 'petition/flag')),
  petitionLaunchCategoriesSetter: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-launch-categories-setter" */ '@/launch-petition/categories-setter')),
  petitionLaunchImageButton: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-launch-image-button" */ '@/launch-petition/image-button')),
  petitionLockableInput: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-lockable-input" */ '@/components/petition-lockable-input')),
  petitionLockableRichText: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-lockable-rich-text" */ '@/components/petition-lockable-rich-text')),
  settingToggle: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "setting-toggle" */ '@/components/setting-toggle')),
  shortUrlSetter: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "short-url-setter" */ '@/components/short-url-setter')),
  trackingFreshchat: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingFreshchat" */ '@/components/tracking/tracking-freshchat')),
  trackingGoogle: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingGoogle" */ '@/components/tracking/tracking-google')),
  trackingGtm: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingGtm" */ '@/components/tracking/tracking-gtm')),
  trackingMatomo: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingMatomo" */ '@/components/tracking/tracking-matomo')),
  trackingOptimizely: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingOptimizely" */ '@/components/tracking/tracking-optimizely')),
  trackingPiwikPro: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingPiwikPro" */ '@/components/tracking/tracking-piwik-pro')),
  trackingSegment: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingSegment" */ '@/components/tracking/tracking-segment')),
  trixTextInput: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trix-text-input" */ '@/components/inputs/trix-text-input')),
  userSignInPage: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "user-sign-in-page" */ '@/users/sign-in-page')),
  userSignUpPage: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "user-sign-up-page" */ '@/users/sign-up-page'))
}

const scriptMapping = {
  campaignNotVisible: () => import(/* webpackMode: "lazy" */ '@/campaign-form-shared/not-visible'),
  carousel: () => import(/* webpackMode: "lazy" */ './home/carousel'),
  clickToCopy: () => import(/* webpackMode: "lazy" */ '@/click-to-copy'),
  collapsibleActions: () => import(/* webpackMode: "lazy" */ '@/phoenix/collapsible-actions'),
  endCampaign: () => import(/* webpackMode: "lazy" */ '@/manage-petition/end-campaign'),
  ensureCorrectSegmentUserId: () => import(/* webpackMode: "lazy" */ '@/analytics/ensure-correct-segment-user-id'),
  formGeoip: () => import(/* webpackMode: "lazy" */ '@/forms/geoip-country-select'),
  localChapterEventsShow: () => import(/* webpackMode: "lazy" */ '@/local-chapters/show-events'),
  localChaptersManage: () => import(/* webpackMode: "lazy" */ '@/local-chapters/manage'),
  namePrivacyNote: () => import(/* webpackMode: "lazy" */ '@/name-privacy-note'),
  notificationMethods: () => import(/* webpackMode: "lazy" */ '@/notification-subscriptions'),
  pageViewTracking: () => import(/* webpackMode: "lazy" */ '@/analytics/page-view-tracking'),
  petitionForm: () => import(/* webpackMode: "lazy" */ '@/petition/form'),
  petitionShow: () => import(/* webpackMode: "lazy" */ '@/petition/show'),
  queuedTrackingEvents: () => import(/* webpackMode: "lazy" */ '@/analytics/queued-tracking-events'),
  recaptcha: () => import(/* webpackMode: "lazy" */ '@/recaptcha'),
  stackedNavigation: () => import(/* webpackMode: "lazy" */ '@/phoenix/stacked-navigation')
}

addComponentLoader(componentMapping, scriptMapping)

addComponentLoader({ blastEmailForm: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "blast-email-form" */ '@/blast-email/form')) },
                   {}, () => import(/* webpackMode: "lazy" */ /* webpackChunkName: "blast-email-form-store-getter" */ '@/blast-email/store'))

addComponentLoader({ petitionBulkSignatures: defineAsyncComponent(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-bulk-signatures" */ '@/petition/bulk-signatures/root')) },
                   {}, () => import(/* webpackMode: "lazy" */ /* webpackChunkName: "blast-email-form-store-getter" */ '@/petition/bulk-signatures/store'))

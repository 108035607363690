import jQuery from 'jquery'

jQuery(() => {
  if (document.getElementById('password-unmask-button')) {
    runUnmaskablePassword()
  }
})

function runUnmaskablePassword() {
  const passwordField = document.getElementById('user_password')
  const showButton = document.getElementById('password-unmask-button')
  showButton.addEventListener('click', () => {
    if (passwordField.getAttribute('type') === 'password') {
      passwordField.setAttribute('type', 'text')
      showButton.textContent = showButton.dataset.hideText
    } else if (passwordField.getAttribute('type') === 'text') {
      passwordField.setAttribute('type', 'password')
      showButton.textContent = showButton.dataset.showText
    }
  })
}

document.addEventListener('passwordFieldLoaded', () => {
  runUnmaskablePassword()
})
import jQuery from 'jquery'
import { trackEvent } from '@/analytics/track'

window.Agra = window.Agra || {}
window.Agra.trackEvent = trackEvent

window.Agra.alertMessageSuccess = function (message) {
  jQuery('.alert').hide()
  jQuery('.client-side-alerts .alert.alert-success > span').html(message)
  jQuery('.client-side-alerts .alert.alert-success').show()
}

window.Agra.alertMessageDanger = function (message) {
  jQuery('.alert').hide()
  jQuery('.client-side-alerts .alert.alert-danger > span').html(message)
  jQuery('.client-side-alerts .alert.alert-danger').show()
}
